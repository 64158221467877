import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from '@utils/http.service';

@Component({
  selector: 'app-validate',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './validate.component.html',
  styleUrl: './validate.component.scss'
})
export class ValidatePage {
  private activatedRoute = inject(ActivatedRoute);
  private http = inject(HttpService)

  public foil: string = '';
  public name: string = '';
  public date: string = '';

  async ngOnInit() {
    this.activatedRoute.params.subscribe(async routeParams => {
      await this.http.get(`https://verificacion.net/api/v1/constanciapenal/${routeParams['foil']}`, false, false, false)
        .then((response: any) => {
          this.foil = response.data.content?.foil;
          this.name = response.data.content?.name;
          this.date = response.data.content?.dateCreated;
        })
    });
  }
}
