import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from '@utils/http.service';

@Component({
  selector: 'app-validate',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './validate.component.html',
  styleUrl: './validate.component.scss'
})
export class ValidatePage {
  private activatedRoute = inject(ActivatedRoute);
  private http = inject(HttpService)

  public foil: string = '';
  public curp: string = '';
  public name: string = '';
  public date: string = '';
  public formaValorada: string = '';
  public folioNo: string = '';
  public reciboNo: string = '';
  public serie: string = '';
  public secuencia: string = '';
  public signBase64: string = '';

  async ngOnInit() {
    this.activatedRoute.params.subscribe(async routeParams => {
      await this.http.get(`https://verificacion.net/api/v1/constanciainhabilitacion/${routeParams['foil']}`, false, false, false)
        .then((response: any) => {
          this.foil = response.data.content?.foil;
          this.curp = response.data.content?.curp;
          this.name = response.data.content?.name;
          this.formaValorada = response.data.content?.electronicSignature.formaValorada;
          this.folioNo = response.data.content?.electronicSignature.folioNo;
          this.reciboNo = response.data.content?.electronicSignature.reciboNo;
          this.serie = response.data.content?.electronicSignature.serie;
          this.secuencia = response.data.content?.electronicSignature.secuencia;
          this.signBase64 = response.data.content?.electronicSignature.base64;
          this.date = response.data.content?.dateCreated;
        })
    });
  }
}
