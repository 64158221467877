<main id="main" class="py-4">
  <div class="container">
    <div class="card border-0 rounded-3 p-1">
      <div class="card-header border rounded-top-3" style="background-color: #E7ECEF;">
        <figure class="m-0">
          <img src="./assets/images/logo_shfp.png" alt="">
        </figure>
      </div>
      <div class="card-body">
        <h4 class="fw-bold" style="color: #54697E;">C. {{name}}</h4>
        <p class="lh-sm" style="color: #7F8C8D;"><small>El presente certificado de constancia de no inhabilitación ha
            sido firmado mediante el uso de la firma electrónica avanzada, amparada por un certificado de la fecha de su
            elaboración; y es valido de conformidad con lo dispuesto en los artículos 1o, 4o, 7o, 8o, 26 y 27, de la ley
            de Firma Electronica Avanzada del Estado de Chiapas y demas aplicables.</small></p>
        <p class="lh-sm" style="color: #7F8C8D;"><small>Los datos mediante los cuales fue emitida la constancia son los
            siguientes:</small></p>

        <div class="p-3 border rounded-1">
          <p class="fw-bold text-end"><small>Forma Valorada: {{formaValorada}}</small></p>
          <p class="fw-bold text-center"><small>CONSTANCIA DE NO INHABILITACION</small></p>
          <p class="fw-bold text-end"><small>Folio No. {{folioNo}}</small></p>
          <p class="fw-bold text-start">A QUIEN CORRESPONDA:</p>
          <p class="fw-bold text-start lh-sm"><small>De conformidad con el artículo 27°. parte infine de la Ley de
              Responsabilidades Administrativas para el Estado de Chiapas; asi como el convenio celebrado por el
              Ejecutivo Federal y el Ejecutivo del Estado, de fecha 20 de febrero de 2008; y el suscrito por el
              Ejecutivo del Estado con los Municipios de Chiapas, para no seleccionar, contratar, nombrar o designar a
              quienes se encuentren inhabilitados para ocupar un empleo, cargo o comisión en el servicio público; la
              Secretaría de la Honestidad y Función Pública, por conducto de la Dirección de Responsabilidades, Hace
              Constar: que en el Padrón de Servidores Públicos Inhabilitados, Federal y Estatal, no se encuentra
              registrado (a):</small>
          </p>
          <p class="fw-bold text-center lh-sm mb-0"><small>{{name}}</small></p>
          <p class="fw-bold text-center lh-sm mb-0"><small>{{curp.slice(0, -8)}}</small></p>
          <p class="fw-bold text-center lh-sm"><small>{{curp}}</small></p>

          <p class="fw-bold text-start lh-sm"><small>No omito externarle, que en caso de ser contratado en alguna
              Dependencia o Entidad de la Administración Pública Estatal, tiene usted la obligación de presentar su
              declaración inicial de situación patrimonial dentro de plazo de 60 días naturales siguientes a la de toma
              de posesión, conforme a lo contemplado por el Artículo 33, fracción I, II y III de la Ley de
              Responsabilidades Administrativas para el Estado de Chiapas, con excepción de aquellos que estén exentos
              de esa obligación.</small></p>

          <p class="fw-bold text-start text-uppercase">{{date | date: "EEEE"}}, {{date | date: "d"}} de {{date | date:
            "MMMM"}} de {{date | date: "y"}}</p>

          <p class="fw-bold text-start lh-sm"><small>"Es estricta responsabilidad de quien contrata o autoriza la
              selección, contratación, nombramiento o designación de una persona para ocupar un empleo, cargo o comisión
              en el servicio público, la de verificar que los datos personales del interesado corresponden al Nombre,
              RFC y CURP de la persona que indica la presente constancia con los que aquella que está
              contratando".</small></p>
          <p class="fw-bold text-start lh-sm"><small>Nota: la siguiente constancia no tendrá validez oficial si los
              datos personales del funcionario público interesado no está escrito correctamente, favor de
              verificarla.</small></p>
          <p class="fw-bold text-start lh-sm"><small>Derechos pagados con el recibo oficial No. {{reciboNo}}</small></p>
          <p class="fw-bold text-start lh-sm"><small>Firmante: <span class="fw-normal">Lic Laureano Rodríguez
                Arcuri</span> Serie: <span class="fw-normal">{{serie}}</span> Secuencia: <span
                class="fw-normal">{{secuencia}}</span> Fecha firmado: <span class="fw-normal">11/07/2024</span></small>
          </p>

          <p class="fw-bold text-start lh-sm mb-0"><small>Firma electronica:</small></p>
          <textarea class="w-100 mb-3 border-0" style="background-color: transparent;height: 100px;color: #000;"
            disabled>{{signBase64}}</textarea>
          <p class="fw-bold text-center mb-0 lh-sm"><small>Tel. 61 8 75 30 Ext. 22312</small></p>
          <p class="fw-bold text-center mb-0 lh-sm"><small>www.shyfpchiapas.gob.mx</small></p>
        </div>
      </div>
      <div class="card-footer border rounded-bottom-3" style="background-color: #222222;">
        <p class="fw-bold" style="color: gray;font-size: 9px;">© Secretaría de la Honestidad y Función Pública - Unidad
          de Informatica y Desarrollo Digital.</p>

        <ul class="list-unstyled" style="color: gray;font-size: 9px;">
          <li>• Blvd. Los castillos No.410, Fracc. Villa Montes Azules</li>
          <li>• Tuxtla Gutiérrez, CP 29056</li>
          <li>• +52(961) 61 8-7530</li>
        </ul>
      </div>
    </div>
  </div>
</main>