import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-no-penal',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './no-penal.component.html',
  styleUrl: './no-penal.component.scss'
})
export class NoPenalPage {
  private activatedRoute = inject(ActivatedRoute);

  public foil: string = '';
  public name: string = '';
  public date: string = '';

  async ngOnInit() {
    this.activatedRoute.params.subscribe(async routeParams => {
      let data = atob(routeParams['data']).split('/')

      this.foil = data[0];
      this.name = data[1];
      this.date = data[3];
    });
  }
}
