import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-no-inhabilitacion',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './no-inhabilitacion.component.html',
  styleUrl: './no-inhabilitacion.component.scss'
})
export class NoInhabilitacionPage {
  private activatedRoute = inject(ActivatedRoute);

  public foil: string = '';
  public curp: string = '';
  public name: string = '';
  public formaValorada: string = '';
  public folioNo: string = '';
  public reciboNo: string = '';
  public serie: string = '';
  public secuencia: string = '';
  public signBase64: string = '';
  public date: string = '';

  async ngOnInit() {
    this.activatedRoute.params.subscribe(async routeParams => {
      let data = atob(routeParams['data']).split('/')

      this.foil = data[0]
      this.name = data[1]
      this.curp = data[2]
      this.formaValorada = data[3]
      this.folioNo = data[4]
      this.reciboNo = data[5]
      this.serie = data[6]
      this.secuencia = data[7]
      this.signBase64 = data[8]
      this.date = data[9]
    });
  }
}
