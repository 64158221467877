import { Routes } from '@angular/router';
import { NoPenalPage } from './no-penal.component';

export const routes: Routes = [
  {
    path: 'nopenal/:data',
    component: NoPenalPage,
    title: 'Antecedentes No Penales | Chiapas',
  },
];
