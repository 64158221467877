import { Routes } from '@angular/router';
import { NoInhabilitacionPage } from './no-inhabilitacion.component';

export const routes: Routes = [
  {
    path: 'noinhabilitacion/:data',
    component: NoInhabilitacionPage,
    title: 'No Inhabilitacion | Chiapas',
  },
];
